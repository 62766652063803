import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
// Components
import Layout from '../components/Layout'
import {
  ButtonStyle,
  CopyStyle,
  SubheadSMStyle,
  SubheadStyle,
} from '../components/UI'
import CallToAction from '../components/CallToAction'
import { Listbox } from '@headlessui/react'
import Seo from '../components/Seo'
import '../styles/job.css';
import { jobQuery, PaginatedItems, StyledSlider } from './jobs';
import MriTechnologistJobHeroImg from '../images/pca-5.webp';
import { graphql } from 'gatsby'
import JobFilters from '../components/JobFilters'
import { JobPageHero } from '../components/JobPageHero'

const MriTechnologistJob = ({ location, pageContext, data }) => {
  const pageData = data.contentfulJobsPage
  const jobs = pageContext.jobs

  const locations = []
  jobs.forEach((job) => {
    const city = job[1].usa_city || job[1].canada_city;
    if (!locations.includes(city) && (city !== undefined || city !== ''))
      locations.push(city)
  })
  locations.sort((a, b) => a.localeCompare(b))

  const [selectedLocation, setSelectedLocation] = useState('Select Location')
  const [rateSliderValue, setRateSliderValue] = useState(20)
  const [order, setOrder] = useState('newest')
  const [orderLabel, setOrderLabel] = useState('Newest')
  const [filterResults, setFilterResults] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [skills, setSkills] = useState([])
  const [searchString, setSearchString] = useState('mri technologist')

  useEffect(() => {
    updateResults()
  }, [rateSliderValue, order])

  useEffect(() => {
    updateResults()
  }, [])

  const filterKeyword = (jobKeyword, keyword) => {
    if (
      jobKeyword &&
      jobKeyword.toLowerCase().includes(keyword.toLowerCase())
    ) {
      return true
    } else {
      return false
    }
  }

  const filterLocation = (jobLocation, location) => {
    return jobLocation
      ? jobLocation.toLowerCase().includes(location.toLowerCase())
      : false
  }

  const filterRate = (jobRate, rate) => {
    if (jobRate && Number(jobRate) >= Number(rate)) {
      return true
    } else {
      return false
    }
  }

  const updateResults = () => {
    // Filter by keyword, location, and rate
    const filtered = jobs.filter((job) => {
      if (
        searchString === ''
          ? true
          : filterKeyword(job[1].title, searchString)
        // ||
        // filterKeyword(job[1].description, searchString)
      ) {
        if (
          selectedLocation === 'Select Location'
            ? true
            : filterLocation(job[1].location, selectedLocation)
        ) {
          if (filterRate(job[1].salary_min, rateSliderValue)) {
            return true
          }
        }
      } else {
        return false
      }
    })
    if (searchString && selectedLocation && selectedLocation !== 'Select Location') {
      window.fbq('track', 'Search', { jobTitle: searchString, location: selectedLocation })
    }

    // Sort
    if (order === 'newest') {
      setOrderLabel('Newest')
      filtered.sort((first, next) => {
        return new Date(next[1].date) - new Date(first[1].date)
      })
    } else if (order === 'rateHighToLow') {
      setOrderLabel('Pay rate highest to lowest')
      filtered.sort((first, next) => {
        return next[1].salary_min - first[1].salary_min
      })
    } else if (order === 'rateLowToHigh') {
      setOrderLabel('Rate Low To High')
      filtered.sort((first, next) => {
        return first[1].salary_min - next[1].salary_min
      })
    }

    setSkills(() => {
      const uniqueSkills = [...new Set(jobs.map((job) => job[1].title))];
      return uniqueSkills.map((skill, index) => ({ id: index, name: skill }));
    });

    // Set filtered results usestate
    setFilterResults(filtered)

    // Set pagination page
    setPageNumber(0)
  }

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metadataTitle || 'Job Board'}
        description={pageData.metadataDescription}
        image={
          pageData.metadataImage
            ? `https:${pageData.metadataImage.file.url}`
            : undefined
        }
      />
      {/* Hero */}
      <JobPageHero heroImg={MriTechnologistJobHeroImg} />

      <section tw="grid grid-cols-12 pt-14 pb-28 lg:(max-w-screen-lg mx-auto pt-16 pb-32)">
        {/* Job Filters */}
        <JobFilters
          orderLabel={orderLabel}
          setOrder={setOrder}
          rateSliderValue={rateSliderValue}
          setRateSliderValue={setRateSliderValue}
        />
        {/* Paginated job results */}
        <div tw="px-[15px] col-span-12 lg:(px-[0])">
          <div css={[SubheadStyle]}>
            {/* <span>{keyword}</span> */}
            <span css={[CopyStyle, tw`inline-block mt-8 mb-2`]}>{`${filterResults.length > 9 ? 9 : filterResults.length
              } of ${filterResults.length} results`}</span>
          </div>
          <PaginatedItems
            allJobs={filterResults}
            itemsPerPage={9}
            page={pageNumber}
            setPage={setPageNumber}
          />
        </div>
      </section>

      {/* CTA */}
      <CallToAction
        header={pageData.ctaHeadline}
        copy={pageData.ctaSubhead.ctaSubhead}
        ctas={[
          { copy: 'Start Earning', destination: '/start-earning' },
          { copy: 'Find Talent', destination: '/find-talent' },
        ]}
        image={pageData.ctaImage}
      />
    </Layout>
  )
}

export default MriTechnologistJob;

export const pageQuery = graphql`
  {
    contentfulJobsPage(pageId: { eq: "Jobs Page" }) {
      heroHeadline
      heroSubhead
      heroImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      ctaHeadline
      ctaSubhead {
        ctaSubhead
      }
      ctaImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      metadataTitle
      metadataDescription
      metadataImage {
        file {
          url
        }
      }
    }
  }
`
